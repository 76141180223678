<template>
    <div class="project-details" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Profile / Change password
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-4 offset-lg-6 col-sm-12 mx-auto mb-4 ml-5 mt-4">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="row">
                            <form class="user mt-5 offset-3">
                                <h3 class="m-0 font-weight-bold mb-5">
                                    Change password
                                </h3>
                                <div class="form-group">
                                    <label>Current password</label>
                                    <input type="password" v-model="password.oldPassword" class="form-control"
                                        :class="{ 'border border-danger': oldPasswordError }" data-toggle="tooltip"
                                        data-placement="top" title="Old password" id="oldPassword-new"
                                        placeholder="Old password">
                                </div>
                                <div class="form-group">
                                    <label>New password</label>
                                    <input type="password" v-model="password.newPassword" data-toggle="tooltip"
                                        data-placement="top" title="Last name" class="form-control" id="newPassword-new"
                                        :class="{ 'border border-danger': newPasswordError }" placeholder="New password">
                                </div>
                                <div class="form-group">
                                    <label>Confirm new password</label>
                                    <input type="password" v-model="password.repeatNewPassword" class="form-control"
                                        data-toggle="tooltip" data-placement="top" title="Confirm new password"
                                        :class="{ 'border border-danger': repeatNewPasswordError }"
                                        id="repeatNewPassword-new" placeholder="Confirm new password">
                                </div>
                                <div class="form-group">
                                    <a href="#" class="btn btn-primary btn-block" @click="changePassword()">
                                        Change password
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import UsersService from "../services/usersService";

export default {
    name: "ProjectDetailsView",
    data: () => {
        return {
            password: {
                oldPassword: null,
                newPassword: null,
                repeatNewPassword: null
            },
            loading: false,
            repeatNewPasswordError: false,
            newPasswordError: false,
            oldPasswordError: false
        };
    },
    components: {
        LoadingIndicator,
    },
    created() {
    },
    methods: {
        changePassword() {

            if (!this.password.oldPassword) {
                const $toast = useToast();
                this.oldPasswordError = true;
                $toast.error('Old password must be provided');

                return;
            }

            if (!this.password.newPassword || this.password.newPassword != this.password.repeatNewPassword || !this.password.repeatNewPassword) {
                const $toast = useToast();
                this.repeatNewPasswordError = true;
                $toast.error('New password and confirm password do not match');

                return;
            }

            UsersService.changePassword(this.password).then(
                () => {
                    const $toast = useToast();
                    $toast.success('New password has been set!');

                }).catch(error => {
                    this.loading = false;
                    const $toast = useToast();
                    $toast.error(error.message);
                })
        }
    },
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}

.btn-light>a {
    color: #7B7B7B;
}

.list-group input[type="radio"] {
    display: none;
}

.list-group input[type="radio"]+.list-group-item {
    cursor: pointer;
}

.list-group input[type="radio"]+.list-group-item:before {
    content: "\2022";
    color: transparent;
    font-weight: bold;
    margin-right: 1em;
}

.list-group input[type="radio"]:checked+.list-group-item {
    background-color: #0275D8;
    color: #FFF;
}

.list-group input[type="radio"]:checked+.list-group-item:before {
    color: inherit;
}
</style>
