import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const ConnectionsService = {
  async getConnections(orderBy = false) {
    try {
      const response = await ApiService.get(
        `/connection-configurations/ordered/${orderBy}`
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async testConnection(id) {
    try {
      const response = await ApiService.put(
        `/connection-configurations/test/${id}`
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async testConnectionDto(dto) {
    try {
      const response = await ApiService.put(
        `/connection-configurations/test`,
        dto
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async createConnection(model) {
    try {
      const response = await ApiService.post(
        "/connection-configurations/",
        model
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async updateConnection(id, connection) {
    try {
      const response = await ApiService.put(
        `/connection-configurations/${id}`,
        connection
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async deleteConnection(id) {
    try {
      const response = await ApiService.delete(
        `/connection-configurations/${id}`
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async getTables(id) {
    try {
      const response = await ApiService.get(
        `/connection-configurations/${id}/tables`
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async extractSpecificTable(id, projectId, jobId, tableName) {
    try {
      var url = `/connection-configurations/${id}/tables/project/${projectId}`;
      if (jobId) {
        url = `/connection-configurations/${id}/tables/project/${projectId}/job/${jobId}`;
      }
      const response = await ApiService.post(url, tableName);
      return response.data;
    } catch (error) {
      if (error.response.data) {
        throw new ApplicationError(
          error.response.data.status,
          error.response.data.error
        );
      } else {
        throw new ApplicationError(error.code, error);
      }
    }
  },
  async deleteSpecificTable(projectId, jobId, tableName) {
    try {
      var url = `/connection-configurations/tables/project/${projectId}`;
      if (jobId) {
        url = `/connection-configurations/tables/project/${projectId}/job/${jobId}`;
      }
      const response = await ApiService.post(url, tableName);
      return response.data;
    } catch (error) {
      if (error.response.data) {
        throw new ApplicationError(
          error.response.data.status,
          error.response.data.error
        );
      } else {
        throw new ApplicationError(error.code, error);
      }
    }
  },
};

export default ConnectionsService;

export { ConnectionsService, ApplicationError };
