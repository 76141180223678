<template>
  <div class="users">
    <LoadingIndicator v-if="loading"></LoadingIndicator>

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="h6 text-gray-600">
        Edit user / {{ userDto.firstName }} {{ userDto.lastName }}
      </h6>
    </div>

    <!-- Content Row -->
    <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
      <div class="col-lg-5 offset-lg-5 col-sm-12 mx-auto  mb-4 mt-4">
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
              <h1 class="m-0 font-weight-bold text-gray-800" style="color: #7b7b7b !important;">
                {{ userDto.firstName }} {{ userDto.lastName }}
              </h1>

              <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown no-arrow">
                  <a class="nav-link dropdown-toggle" href="#" id="projectDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <h6><i class="fa-solid fa-ellipsis"></i></h6>
                  </a>
                  <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in"
                    aria-labelledby="projectDropdown">
                    <a class="dropdown-item text-danger" v-if="userDto.isActive" href="#" data-dismiss="modal"
                      v-on:click="selectUser(user.id)" data-toggle="modal" data-target="#deactivateUserModal">
                      <i class="fa-solid fa-trash-can"></i>
                      Deactivate user
                    </a>
                    <a class="dropdown-item text-success" v-else href="#" data-dismiss="modal"
                      v-on:click="activateUser(user.id)">
                      <i class="fa-solid fa-rotate-left"></i>
                      Activate user
                    </a>
                    <a class="dropdown-item text-warning" href="#" v-on:click="changeUserPassword(userDto.id)">
                      <i class="fa-solid fa-key"></i>
                      Reset password
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card shadow-sm mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-lg 12 col-md-12">
                <form class="user mt-5">
                  <div class="form-row">
                    <div class="form-group col-md-6 col-lg-6">
                      <label class="text-gray-800">First name</label>
                      <input type="text" v-model="userDto.firstName" class="form-control"
                        :class="{ 'border border-danger': firstNameError }" data-toggle="tooltip" data-placement="top"
                        title="First name" id="firstName-new" placeholder="First name">
                    </div>
                    <div class="form-group col-md-6 col-lg-6">
                      <label class="text-gray-800">Last name</label>
                      <input type="text" v-model="userDto.lastName" data-toggle="tooltip" data-placement="top"
                        title="Last name" class="form-control" id="lastName-new"
                        :class="{ 'border border-danger': lastNameError }" placeholder="Last name">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6 col-lg-6">
                      <label class="text-gray-800">Phone number</label>
                      <input type="text" v-model="userDto.phoneNumber" class="form-control" data-toggle="tooltip"
                        data-placement="top" title="Phone number" id="phoneNumber-new" placeholder="Phone number">
                    </div>
                    <div class="form-group col-md-6 col-lg-6">
                      <label class="text-gray-800">Client</label>
                      <select v-model="userDto.tenantId" class="custom-select custom-select-md mb-3" data-toggle="tooltip"
                        data-placement="top" title="Client" :class="{ 'border border-danger': tenantIdError }"
                        id="tenant-edit" placeholder="Client">
                        <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between" style="backround-color: #fff">
                      <span class="m-0 font-weight-bold text-gray-800">
                      </span>
                      <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm ml-1"
                        @click="updateUser()" style="min-width: 74px;">
                        Save
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm mt-3">
          <div class="card-body">
            <div class="row border mt-3 ml-1 mr-1 rounded p-2 no-arrow">
              <div class="col-lg 12 col-md-12">
                <span class="text-gray-500">Roles</span>
                <ul class="ml-auto list-unstyled">
                  <li class="border rounded pl-2 pr-2 mt-2 no-arrow" v-for="role in userDto.roles" :key="role.id">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between" style="backround-color: #fff">
                      <span class="m-0 font-weight-bold text-gray-800">{{ role.name }}</span>
                      <a class="btn btn-danger btn-sm mb-1 mr-1" @click="removeRoleFromUser(role.id)"
                        style="min-width: 75px;">Remove</a>
                    </div>
                  </li>
                  <li class="border rounded pl-2 pr-2 mt-2 no-arrow">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between" style="backround-color: #fff">
                      <select v-model="selectedRoleId" class="custom-select custom-select-md mb-2"
                        style="max-width: 300px;" data-toggle="tooltip" data-placement="top" title="Role" id="role-assign"
                        placeholder="Role">
                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                      </select>
                      <a class="btn btn-primary btn-sm mr-1" style="min-width: 74px;" @click="addRoleToUser()">Assign</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div></div>
      </div>
    </div>

    <ContentWrapper>

      <div class="modal fade" id="deactivateUserModal" tabindex="-1" role="dialog" aria-labelledby="deactivateUserModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6>Are you sure you want to deactivate user?</h6>
              <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" type="button" data-dismiss="modal">
                Cancel
              </button>
              <a class="btn btn-primary" data-dismiss="modal" v-on:click="deactivateUser()">Deactivate</a>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";
import ClientsService from "../services/clientsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ContentWrapper from '../components/ContentWrapper.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'UserView',
  components: { LoadingIndicator, ContentWrapper },
  data: () => {
    return {
      loading: true,
      clients: [],
      roles: [],
      userDto: {
        firstName: null,
        lastName: null,
        email: null,
        tenantId: null,
        phoneNumber: null,
        isActive: null
      },
      selectedUserDto: {
        firstName: null,
        lastName: null,
        email: null,
        tenantId: 0,
        roles: [],
        phoneNumber: null
      },
      selectedUserId: null,
      selectedRoleId: null,
      firstNameError: false,
      lastNameError: false,
      tenantIdError: false,
      emailError: false,
      userId: null
    }
  },
  computed: {
  },
  created() {

    const route = useRoute();
    this.userId = route.params.id;

    this.getUser();
    this.getRoles();
    this.getClients();
  },
  watch: {
    'userDto.firstName': function (newTerm) {
      if (!newTerm) {
        this.firstNameError = true;
      } else {
        this.firstNameError = false;
      }
    },
    'userDto.lastName': function (newTerm) {
      if (!newTerm) {
        this.lastNameError = true;
      } else {
        this.lastNameError = false;
      }
    },
    'userDto.email': function (newTerm) {
      if (!newTerm) {
        this.emailError = true;
      } else {
        this.emailError = false;
      }
    },
    'userDto.tenantId': function (newTerm) {
      if (!newTerm) {
        this.tenantIdError = true;
      } else {
        this.tenantIdError = false;
      }
    },
  },
  methods: {
    getUser() {
      UsersService.getUser(this.userId).then((user) => {
        this.userDto = user;
        this.loading = false;
      });
    },
    getRoles() {
      UsersService.getRoles().then((roles) => {
        this.roles = roles;
        this.loading = false;
      });
    },
    getClients() {
      ClientsService.getClients(true).then((clients) => {
        this.clients = clients;
        this.loading = false;
      });
    },
    validateEditUser() {
      let isOk = true;

      if (!this.userDto.firstName) {
        this.firstNameError = true;
        isOk = false;
      }

      if (!this.userDto.lastName) {
        this.lastNameError = true;
        isOk = false;
      }

      if (!this.userDto.tenantId) {
        this.tenantIdError = true;
        isOk = false;
      }

      if (!isOk) {
        const $toast = useToast();
        $toast.error('Please fill in required fields');
      }

      return isOk;
    },
    selectUser(id) {
      this.selectedUserId = id;
    },
    deactivateUser() {
      this.loading = true;
      UsersService.deleteUser(this.selectedUserId).then(() => {
        const $toast = useToast();
        $toast.success('User deactivated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    activateUser(id) {
      this.loading = true;
      UsersService.activateUser(id).then(() => {
        const $toast = useToast();
        $toast.success('User activated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    updateUser() {
      this.loading = true;

      if (!this.validateEditUser()) {
        this.loading = false;
        return;
      }

      UsersService.updateUser(this.userId, this.userDto).then(() => {
        const $toast = useToast();
        $toast.success('Update successful!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    selectEditUser(id) {
      this.selectedUserDto = this.users.find(x => x.id == id);
    },
    addRoleToUser() {
      this.loading = true;
      UsersService.assignRoleToUser(this.userId, this.selectedRoleId).then(() => {
        const $toast = useToast();
        $toast.success('Role added successfully!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    removeRoleFromUser(roleId) {
      this.loading = true;
      UsersService.removeRoleFromUser(this.userId, roleId).then(() => {
        const $toast = useToast();
        $toast.success('Role removed successfully!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    changeUserPassword(userId) {
      this.loading = true;
      UsersService.changeUserPassword(userId).then(() => {
        const $toast = useToast();
        $toast.success('Password changed successfully!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
  }
}
</script>
<style>
.card {
  text-align: left;
}

.hd-bg-color {
  background-color: #f1f1f1;
}

.card-header {
  background-color: #fff;
  border-bottom: 0;
}

.nav-item>a {
  color: #7b7b7b;
  font-weight: bolder;
}

.badge-info {
  color: #0469b1;
  background-color: #c6e7ff;
}

.bg-info {
  background-color: #a7ebf5;
}
</style>
