import AuthService from "../services/authService";
import UsersService from "../services/usersService";
import { defineStore } from "pinia";

export const authStore = defineStore({
  id: "authStore",
  state: () =>  {
    return {
      authUser: {
        status: { loggedIn: false }, user: null
      }
    }
  },
  getters: {
    getCurrentUser: (state) => {
      return () => state.authUser.user ? state.authUser : UsersService.getCurrent().then(
        (user) => {
          state.authUser.user = user;
        }
      );
    }
  }, 
  actions: {
    login(user) {
      return AuthService.login(user).then(
        (user) => {
          UsersService.getCurrent().then(
            (user) => {
              this.authUser.user = user;
            }
          )
          
          return Promise.resolve(user);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    logout() {
      AuthService.logout();
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    }
  },
});
