import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueSignalR from './services/signalRService.js';

const pinia = createPinia()
const app = createApp(App)

const baseUrl = "https://api-dev.hurodata.com/api/";
const extractionsUrl = baseUrl + "projects/extractions";

app.use(pinia)
app.use(router)
app.use(VueSignalR, extractionsUrl)
app.mount('#app')
