import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const StorageViewerService = {
  async getStorage() {
    try {
      const response = await ApiService.get('/storage-viewer');
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getStorageFiles(id) {
    try {
      const response = await ApiService.get(`/storage-viewer/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async downloadFile(id) {
    try {
      const response = await ApiService.download(`/projects/job/files/download/${id}`);
      console.log(response);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
};

export default StorageViewerService;

export { StorageViewerService, ApplicationError };
