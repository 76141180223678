import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const ClientsService = {
  async getClients(orderBy) {
    try {
      const response = await ApiService.get(`/tenants/ordered/${orderBy}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getOnlyClients(orderBy) {
    try {
      const response = await ApiService.get(`/tenants/clients/ordered/${orderBy}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getNonOrderedClients() {
    try {
      const response = await ApiService.get('/tenants/');
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getClient(id) {
    try {
      const response = await ApiService.get(`/tenants/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async createClient(model) {
    try {
      const response = await ApiService.post("/tenants/", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async updateClient(id, client) {
    try {
      const response = await ApiService.put(`/tenants/${id}`, client);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async deleteClient(id) {
    try {
      const response = await ApiService.delete(`/tenants/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async activateClient(id) {
    try {
      const response = await ApiService.put(`/tenants/activate/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getPartnerClients(order) {
    try {
      const response = await ApiService.get(`/tenants/partners/ordered/${order}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getClientsFromPartner(id, order) {
    try {
      const response = await ApiService.get(`/tenants/partners/${id}/ordered/${order}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getSettings() {
    try {
      const response = await ApiService.get('/tenant-settings/');
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getLogo() {
    try {
      const response = await ApiService.get('/tenant-settings/logo');
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async uploadLogo(formData) {
    try {
      const response = await ApiService.upload('/tenant-settings/logo',formData);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
};

export default ClientsService;

export { ClientsService, ApplicationError };
