
<template>
    <!-- Page Heading -->
    <div class="row mb-6" style="text-align: left">
        <div class="col-xl-9 col-md-9 col-sm-9">
            <ul class="nav navbar navbar-left d-flex d-inline-flex shadow p-3 rounded-lg">
                <li class="nav-item d-inline-flex align-items-center mr-2">
                    <a class="nav-link d-inline-flex" href="#">{{ title }}</a>
                </li>
                <li class="nav-item d-inline-flex align-items-center mr-2">
                    <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-200 navbar-search">
                        <div class="input-group">
                            <input type="text" class="form-control bg-light border-0 small" v-model="searchTerm"
                                placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" data-toggle="tooltip" title="Search"
                                    data-placement="top">
                                    <i class="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-primary" data-toggle="tooltip" title="Sort" data-placement="top"
                                    v-on:click="sort()" type="button">
                                    <i class="fa fa-solid fa-sort"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    </div>
</template>
    
<script>

export default {
    props: ['title'],
    data: () => {
        return {
            searchTerm: null,
        };
    },
    methods: {
        sort() {
            this.$parent.$parent.sort();
        }
    },
    watch: {
        searchTerm(newTerm) {
            this.$parent.$parent.searchTerm = newTerm;
        }
    },
}
</script>