<template>
<div class="overlay"></div>
<div class="spanner show">
  <div class="pixel-spinner">
    <div class="pixel-spinner-inner"></div>
  </div>
</div>
</template>`

<script>

export default {
  data() {
    return {
      count: 0
    }
  }
}
</script>

<style>
.pixel-spinner,
.pixel-spinner * {
  box-sizing: border-box;
}

.pixel-spinner {
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pixel-spinner .pixel-spinner-inner {
  width: calc(70px / 7);
  height: calc(70px / 7);
  background-color: #ff1d5e;
  color: #ff1d5e;
  box-shadow: 15px 15px 0 0,
    -15px -15px 0 0,
    15px -15px 0 0,
    -15px 15px 0 0,
    0 15px 0 0,
    15px 0 0 0,
    -15px 0 0 0,
    0 -15px 0 0;
  animation: pixel-spinner-animation 2000ms linear infinite;
  margin-top: 600px;
}

@keyframes pixel-spinner-animation {
  50% {
    box-shadow: 20px 20px 0px 0px,
      -20px -20px 0px 0px,
      20px -20px 0px 0px,
      -20px 20px 0px 0px,
      0px 10px 0px 0px,
      10px 0px 0px 0px,
      -10px 0px 0px 0px,
      0px -10px 0px 0px;
  }

  75% {
    box-shadow: 20px 20px 0px 0px,
      -20px -20px 0px 0px,
      20px -20px 0px 0px,
      -20px 20px 0px 0px,
      0px 10px 0px 0px,
      10px 0px 0px 0px,
      -10px 0px 0px 0px,
      0px -10px 0px 0px;
  }

  100% {
    transform: rotate(360deg);
  }
}
.spanner{
  position:absolute;
  top: 50%;
  left: 0;
  background: #2a2a2a55;
  width: 100%;
  display:block;
  text-align:center;
  height: 100%;
  color: #FFF;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
}

.overlay{
  position: fixed;
	width: 100%;
	height: 100%;
  background: rgba(0,0,0,0.5);
  visibility: hidden;
}

.pixel-spinner,
.pixel-spinner:before,
.pixel-spinner:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.pixel-spinner {
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.pixel-spinner:before,
.pixel-spinner:after {
  content: '';
  position: absolute;
  top: 0;
}
.pixel-spinner:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.pixel-spinner:after {
  left: 3.5em;
}

.show{
  visibility: visible;
}

.spanner, .overlay{
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.spanner.show, .overlay.show {
	opacity: 1
}

</style>