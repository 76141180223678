<template>
    <div class="project" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-6 col-md-6 offset-lg-6 offset-md-6 mx-auto  mb-4 ml-5 mt-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <div class="form-row form-inline" style="backround-color: #fff">
                            <div class="input-group col-md-12">
                                <label class="font-weight-bold">Name:</label>
                                <input type="text" v-model="job.name" class="form-control bg-light border-0"
                                    placeholder="Job name" />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <form-wizard step-size="xs" color="#04B187" @on-complete="finalize()">

                            <tab-content title="Connection" :beforeChange="validateConnection" style="min-height: 400px;">
                                <ConnectionComponent :project=project @connectionChanged="getProject" title="Connection"
                                    description="Check your connection before adding a job" :editMode="true">
                                </ConnectionComponent>
                            </tab-content>

                            <tab-content title="Select tables" style="min-height: 400px;">
                                <div class="list-inline" v-if="tables">
                                    <div class="row overflow-auto">
                                        <li href="#" class="list-inline-item mb-2 m-2" style="border:0" v-for="table in tables" :key="table.id">
                                            <div class="d-sm-flex align-items-center justify-content-between"
                                            :class="{ 'active-table': table.isChecked, 'inactive-table': !table.isChecked }"
                                                style="backround-color: #fff"  >
                                                <span class="m-1 mb-1 font-weight-bold"
                                                    style="color:#a1a1a1; min-width:100px; cursor: pointer;" v-on:click.prevent="checkTable(table.id, table.isChecked)">
                                                    {{ table.tabname }}
                                                </span>
                                                <ul class="navbar-nav ml-auto pr-2 mt-1">
                                                    <li class="nav-item dropdown no-arrow">
                                                        <a class="nav-link dropdown-toggle" href="#" id="projectDropdown" role="button"
                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in"
                                                            aria-labelledby="projectDropdown">
                                                            <a href="#" class="dropdown-item text-danger" style="color:#7B7B7B;" v-on:click="removeTable(table.tabname)"><i class="fa-solid fa-trash-can"></i>
                                                            Remove </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </div>
                                    <div class="row mt-4">
                                        <form class="d-none d-sm-inline-block form-inline mr-auto">
                                            <div class="input-group">
                                                <input type="text" class="form-control bg-light border-0 small"
                                                    v-model="newTable" placeholder="Add new table"
                                                    aria-label="Add new table" aria-describedby="basic-addon2" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" type="button"
                                                        v-on:click.prevent="addNewTable()">
                                                        <i class="fa-solid fa-square-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Select fields and priority" style="min-height: 400px;">
                                <div id="accordion">
                                    <div class="row overflow-auto" style="height: 400px;">
                                        <div class="col-md-12">
                                            <draggable tag="div" v-model="checkedTables" item-key="tabname">
                                                <template #item="{ element }">

                                                    <div class="card">
                                                        <div class="card-header" :id='"headingOne" + element.tabname'>
                                                            <h5 class="mb-0">
                                                                <div class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded"
                                                                    style="backround-color: #fff">
                                                                    <span class="m-0 font-weight-bold">
                                                                        <i class="fa-solid fa-grip-vertical"></i> {{
                                                                            element.tabname
                                                                        }}
                                                                    </span>
                                                                    <button class="btn btn-sm btn-light"
                                                                        style="min-width:100px;" data-toggle="collapse"
                                                                        v-on:click.prevent="selectTable(element.id)"
                                                                        v-if="checkedFieldsCount(element.id) == 0"
                                                                        :data-target='"#acc" + element.id'
                                                                        aria-expanded="true" aria-controls="collapseOne">
                                                                        <span style="color:#0469B1"><i
                                                                                class="fa-solid fa-plus"></i>Add
                                                                            fields</span>
                                                                    </button>
                                                                    <button class="btn btn-sm btn-light"
                                                                        style="min-width:100px;"
                                                                        v-on:click.prevent="selectTable(element.id)"
                                                                        data-toggle="collapse"
                                                                        v-if="checkedFieldsCount(element.id) > 0"
                                                                        :data-target='"#acc" + element.id'
                                                                        aria-expanded="true" aria-controls="collapseOne">
                                                                        <span style="color:#0469B1">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </h5>
                                                        </div>
                                                        <div :id='"acc" + element.id' class="collapse"
                                                            aria-labelledby="headingOne" data-parent="#accordion">
                                                            <div class="card-body">
                                                                <div class="d-sm-flex align-items-center justify-content-between border p-2 rounded"
                                                                    style="backround-color: #fff">
                                                                    <span class="m-0" style="font-size:smaller;">
                                                                        Selected fields {{ checkedFieldsCount(element.id) }}
                                                                    </span>
                                                                    <span class="m-0" style="font-size:smaller;"
                                                                        v-if="allCheckedChecker(element.id)">
                                                                        <a href="#" class="text-success"
                                                                            v-on:click.prevent="checkAll(element.id)"><i
                                                                                class="fa-solid fa-circle-check pr-2"></i>
                                                                            Select
                                                                            all
                                                                        </a>
                                                                    </span>
                                                                    <span class="m-0" style="font-size:smaller;" v-else>
                                                                        <a href="#"
                                                                            v-on:click.prevent="checkAll(element.id)"><i
                                                                                class="fa-sharp fa-regular fa-circle pr-2"
                                                                                style="color:#d1d1d1"></i> Select all </a>
                                                                    </span>
                                                                </div>
                                                                <div class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded"
                                                                    style="backround-color: #fff">
                                                                    <div class="input-group">
                                                                        <input type="text" v-model="searchTerm"
                                                                            class="form-control bg-light border-0"
                                                                            placeholder="Search fields" aria-label="Search"
                                                                            aria-describedby="basic-addon2" />
                                                                    </div>
                                                                </div>
                                                                <div class="overflow-auto" style="height: 300px;">
                                                                    <a href="#" class="list-inline-item m-1 mb-2"
                                                                        style="border: 0;" v-for="field in filteredFields"
                                                                        :key="field.id"
                                                                        v-on:click.prevent="checkField(element.id, field.id, field.isChecked)">
                                                                        <div v-if="!field.isChecked"
                                                                            class="d-sm-flex align-items-center justify-content-between p-1"
                                                                            style="backround-color: #fff">
                                                                            <i class="fa-sharp fa-regular fa-circle pr-2"
                                                                                style="color:#d1d1d1"></i>
                                                                            <span class="m-1 mb-1 font-weight-bold"
                                                                                style="color:#a1a1a1; min-width:100px;">
                                                                                {{ field.fieldname }}
                                                                            </span>
                                                                        </div>
                                                                        <div v-else
                                                                            class="d-sm-flex align-items-center justify-content-between p-1">
                                                                            <i
                                                                                class="fa-solid fa-circle-check pr-2 text-success"></i>
                                                                            <span
                                                                                class="m-1 mb-1 font-weight-bold text-success"
                                                                                style="min-width:100px;">
                                                                                {{ field.fieldname }}
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </template>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Filters and joins" style="min-height: 400px;">
                                <div id="accordionFinalized">
                                    <div class="row overflow-auto" style="height: 400px;">
                                        <div class="col-md-12">
                                            <div class="card" v-for="checkedTable in checkedTables" :key="checkedTable.id">
                                                <div class="card-header" :id='"headingOne" + checkedTable.tabname'>
                                                    <h5 class="mb-0">
                                                        <div class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded"
                                                            style="backround-color: #fff">
                                                            <span class="m-0 font-weight-bold">
                                                                {{ checkedTable.tabname }}
                                                            </span>
                                                            <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                                data-toggle="collapse"
                                                                v-on:click.prevent="selectTable(checkedTable.id)"
                                                                v-if="checkedTable.id != selectedTableId"
                                                                :data-target='"#acc" + checkedTable.id' aria-expanded="true"
                                                                aria-controls="collapseOne">
                                                                <span style="color:#0469B1">Details</span>
                                                            </button>
                                                            <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                                v-on:click.prevent="selectTable(checkedTable.id)"
                                                                data-toggle="collapse"
                                                                v-if="checkedTable.id == selectedTableId"
                                                                :data-target='"#acc" + checkedTable.id' aria-expanded="true"
                                                                aria-controls="collapseOne">
                                                                <span style="color:#0469B1">Details</span>
                                                            </button>
                                                        </div>
                                                    </h5>
                                                </div>
                                                <div :id='"acc" + checkedTable.id' class="collapse"
                                                    aria-labelledby="headingOne" data-parent="#accordionFinalized">
                                                    <div class="card-body">
                                                        <h6 class="h6">Advanced filtering</h6>
                                                        <div class="d-sm-flex align-items-center justify-content-between border p-2 mb-2 rounded"
                                                            style="backround-color: #fff">
                                                            <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                                data-toggle="modal" data-target="#filtersModal"
                                                                v-on:click.prevent="selectTable(checkedTable.id)">
                                                                <span>Value filter</span>
                                                            </button>
                                                            <button class="btn btn-sm btn-info" style="min-width:10px;"
                                                                data-toggle="modal" data-target="#joinsModal"
                                                                v-on:click.prevent="selectTable(checkedTable.id)">
                                                                <span>Join fields</span>
                                                            </button>
                                                        </div>

                                                        <div class="d-sm-flex align-items-center justify-content-between border p-2 mt-5 rounded"
                                                            style="backround-color: #fff">
                                                            <span class="m-0" style="font-size:smaller;">
                                                                Selected date fields {{
                                                                    checkedDateFieldsCount(checkedTable.id) }}
                                                            </span>
                                                            <span class="m-0" style="font-size:smaller;"
                                                                v-if="allDateCheckedChecker(checkedTable.id)">
                                                                <a href="#" class="text-success"
                                                                    v-on:click.prevent="checkDateAll(checkedTable.id)"><i
                                                                        class="fa-solid fa-circle-check pr-2"></i> Select
                                                                    all </a>
                                                            </span>
                                                            <span class="m-0" style="font-size:smaller;" v-else>
                                                                <a href="#"
                                                                    v-on:click.prevent="checkDateAll(checkedTable.id)"><i
                                                                        class="fa-sharp fa-regular fa-circle pr-2"
                                                                        style="color:#d1d1d1"></i> Select all </a>
                                                            </span>
                                                        </div>
                                                        <div class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded"
                                                            style="backround-color: #fff">
                                                            <div class="input-group">
                                                                <input type="text" v-model="searchDateFieldTerm"
                                                                    class="form-control bg-light border-0"
                                                                    placeholder="Search fields" aria-label="Search"
                                                                    aria-describedby="basic-addon2" />
                                                            </div>
                                                        </div>
                                                        <div class="overflow-auto" style="height: 300px;">
                                                            <a href="#" class="list-inline-item m-1 mb-2" style="border: 0;"
                                                                v-for="field in filteredDateFields" :key="field.id"
                                                                v-on:click.prevent="checkDateField(checkedTable.id, field.id, field.checkedDate)">
                                                                <div v-if="!field.checkedDate"
                                                                    class="d-sm-flex align-items-center justify-content-between p-1"
                                                                    style="backround-color: #fff">
                                                                    <i class="fa-sharp fa-regular fa-circle pr-2"
                                                                        style="color:#d1d1d1"></i>
                                                                    <span class="m-1 mb-1 font-weight-bold"
                                                                        style="color:#a1a1a1; min-width:100px;">
                                                                        {{ field.fieldname }}
                                                                    </span>
                                                                </div>
                                                                <div v-else
                                                                    class="d-sm-flex align-items-center justify-content-between p-1">
                                                                    <i
                                                                        class="fa-solid fa-circle-check pr-2 text-success"></i>
                                                                    <span class="m-1 mb-1 font-weight-bold text-success"
                                                                        style="min-width:100px;">
                                                                        {{ field.fieldname }}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" id="filtersModal" tabindex="-1" role="dialog"
                                    aria-labelledby="filtersModal" aria-hidden="true">
                                    <div class="modal-dialog modal-lg" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Value filter</h5>
                                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="wheres.length > 0">
                                                    <div class="form-row" v-for="(where, index) in selectedWheres"
                                                        :key="index">
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.field">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.action">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.value">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.operator">
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click.prevent="deleteWhere(where.where.field, where.where.action, where.where.value, where.where.operator)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-3">
                                                            <select id="field" class="form-control" v-model="where.field"
                                                                placeholder="Field">
                                                                <option disabled v-bind:value="null">Field</option>
                                                                <option v-for="field in filteredFields" :key="field.id"
                                                                    :value="field.fieldname"> {{
                                                                        field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <select id="action" class="form-control" v-model="where.action">
                                                                <option disabled v-bind:value="null">Action</option>
                                                                <option value="EQUAL">Equal</option>
                                                                <option value="NOT_EQUAL">Not Equal</option>
                                                                <option value="GREATER_THAN">Greater than</option>
                                                                <option value="GREATER_OR_EQUAL_THAN">Greater or Equal than
                                                                </option>
                                                                <option value="LOWER_THAN">Lower than</option>
                                                                <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                                </option>
                                                                <option value="CONTAINS">Contains</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control" id="whereValue"
                                                                v-model="where.value" placeholder="Value">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <select id="operator" class="form-control"
                                                                v-model="where.operator">
                                                                <option disabled v-bind:value="null">Operator</option>
                                                                <option value="AND">AND</option>
                                                                <option value="OR">OR</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click.prevent="saveWhereClause()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="joinsModal" tabindex="-1" role="dialog"
                                    aria-labelledby="joinsModal" aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Join fields</h5>
                                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="joins.length > 0">
                                                    <div class="form-row" v-for="(join, index) in selectedJoins"
                                                        :key="index">
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldSource">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.action">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.tableDestination">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldDestination">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.operator">
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.group">
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click.prevent="deleteJoin(join.join.fieldSource, join.join.action, join.join.tableDestination, join.join.fieldDestination, join.join.operator, join.join.group)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-2">
                                                            <select id="fieldS" class="form-control"
                                                                v-model="join.fieldSource">
                                                                <option disabled v-bind:value="null">Source field</option>
                                                                <option v-for="field in filteredFields" :key="field.id"> {{
                                                                    field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="action" class="form-control" v-model="join.action">
                                                                <option disabled v-bind:value="null">Action</option>
                                                                <option value="EQUAL">Equal</option>
                                                                <option value="NOT_EQUAL">Not Equal</option>
                                                                <option value="GREATER_THAN">Greater than</option>
                                                                <option value="GREATER_OR_EQUAL_THAN">Greater or Equal than
                                                                </option>
                                                                <option value="LOWER_THAN">Lower than</option>
                                                                <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                                </option>
                                                                <option value="CONTAINS">Contains</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="fieldD" class="form-control"
                                                                v-on:change="changeTableFields($event)"
                                                                v-model="join.tableId">
                                                                <option disabled v-bind:value="null">Destination table
                                                                </option>
                                                                <option v-for="table in tables" :key="table.id"
                                                                    :value="table.id">{{ table.tabname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="table" class="form-control"
                                                                v-model="join.fieldDestination">
                                                                <option disabled v-bind:value="null">Destination field
                                                                </option>
                                                                <option v-for="field in joinTableFields" :key="field.id"
                                                                    :value="field.fieldname">{{ field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="operator" class="form-control"
                                                                v-model="join.operator">
                                                                <option disabled v-bind:value="null">Operator</option>
                                                                <option value="AND">AND</option>
                                                                <option value="OR">OR</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="group" class="form-control" v-model="join.group">
                                                                <option disabled v-bind:value="null">Group</option>
                                                                <option value="First">First</option>
                                                                <option value="Second">Second</option>
                                                                <option value="Third">Third</option>
                                                                <option value="Fourth">Fourth</option>
                                                                <option value="Fifth">Fifth</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click.prevent="saveJoin()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Schedule" style="min-height: 400px;">

                                <div class="row overflow-auto" style="min-height:800px">
                                    <div class="col-md-12 col-sm-12">
                                        <form>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <span data-toggle="tooltip" class="text-info float-right" disabled
                                                        data-placement="auto" title="Choose frequency and occurrence that starts from Start date, eg:
    1-Day - runs every day
    3-Day runs every 3 days
    1-Week runs once a week
    3-Week runs every 3 weeks
    1-Month runs every month
    3-Month runs every 3 months
    Once - will run once on selected date">
                                                        <i class="fa-regular fa-circle-question"></i>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <h6 class="h5">Project filter dates </h6>
                                                    <div style="min-width:100px;">From:</div>
                                                    <datepicker v-model="project.dateStart"></datepicker>
                                                    <div style="min-width:100px;">To:</div>
                                                    <datepicker v-model="project.dateEnd"></datepicker>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input class="form-check-input" type="checkbox" id="dateOverride"
                                                        v-model="overrideDates" @change="dateOverrideChange($event)">
                                                    <label class="form-check-label h5" style="font-weight: normal;"
                                                        for="dateOverride">
                                                        Custom date filter
                                                    </label>

                                                    <div v-if="overrideDates">

                                                        <div style="min-width:100px;">From:</div>
                                                        <datepicker v-model="job.calendar.dateFrom"
                                                            :class="{ 'border border-danger': dateFromError }"></datepicker>
                                                        <div style="min-width:100px;">To:</div>
                                                        <datepicker v-model="job.calendar.dateTo"
                                                            :class="{ 'border border-danger': dateToError }"></datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row" style="border-top: 1px solid #d1d3e2; z-index:99999 !important">
                                                <div class="form-group col-md-6 mt-4">
                                                    <div style="min-width:100px;">Start:</div>
                                                    <datepicker :class="{ 'border border-danger': dateStartError }"
                                                        v-model="job.calendar.dateStart" :disabled-dates="disabledDates">
                                                    </datepicker>
                                                </div>
                                                <div class="form-group col-md-6 mt-4" v-if="!overrideDates">
                                                    <div style="min-width:100px;">End:</div>
                                                    <datepicker v-model="job.calendar.endDate"
                                                        :disabled-dates="disabledDates"></datepicker>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <div style="min-width:100px;">Current time:</div>
                                                    {{ timeData.HH }}
                                                    <span v-if="timeData.HH && timeData.mm">:</span>
                                                    {{ timeData.mm }}
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <div style="min-width:100px;">New time:</div>
                                                    <VueTimePicker :format="timeFormat" :minute-interval="15"
                                                        style="max-width:200px;" v-model="timeData">
                                                    </VueTimePicker>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <div style="min-width:100px;">Occurrence: </div>
                                                    <select id="occurrence" class="form-control" :disabled="overrideDates"
                                                        v-model="job.calendar.occurrence" placeholder="Occurrence"
                                                        style="max-width: 200px;">
                                                        <option value="NONE">None</option>
                                                        <option value="DAY">Day</option>
                                                        <option value="WEEK">Week</option>
                                                        <option value="MONTH">Month</option>
                                                        <option value="ONCE">Once</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <div style="min-width:100px;">Frequency: </div>
                                                    <input type="text" class="form-control" v-model="job.calendar.step"
                                                        :disabled="overrideDates"
                                                        :class="{ 'border border-danger': stepError }"
                                                        placeholder="Frequency" aria-label="Update"
                                                        aria-describedby="basic-addon2" style="max-width:200px;" />
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">

                                                    <p>Choose default period to extract data from</p>
                                                    <label style="min-width:100px;font-weight: normal;">Period: </label>
                                                    <select id="occurrence" class="form-control" :disabled="overrideDates"
                                                        v-model="job.calendar.period" placeholder="Period"
                                                        style="max-width: 200px;">
                                                        <option value="NONE">None</option>
                                                        <option value="PREVIOUS_DAY">Previous day</option>
                                                        <option value="CURRENT_DAY">Current day</option>
                                                        <option value="PREVIOUS_WEEK">Previous week</option>
                                                        <option value="CURRENT_WEEK">Current week</option>
                                                        <option value="PREVIOUS_MONTH">Previous month</option>
                                                        <option value="CURRENT_MONTH">Current month</option>
                                                        <option value="PREVIOUS_QUARTER">Previous quarter</option>
                                                        <option value="CURRENT_QUARTER">Current quarter</option>
                                                        <option value="PREVIOUS_HALF_YEAR">Previous half year</option>
                                                        <option value="CURRENT_HALF_YEAR">Current half year</option>
                                                        <option value="PREVIOUS_YEAR">Previous year</option>
                                                        <option value="CURRENT_YEAR">Current year</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="modal fade" id="infoScheduleModal" tabindex="-1" role="dialog"
                                    aria-labelledby="infoScheduleModal" aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Examples</h5>
                                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="input-group col-md-12">
                                                    <p>Choose frequency and occurrence that starts from
                                                        Start date,
                                                        eg:
                                                    </p>
                                                </div>
                                                <div class="input-group col-md-12">
                                                    <ul>
                                                        <li>1-Day - runs every day</li>
                                                        <li>3-Day runs every 3 days</li>
                                                        <li>1-Week runs once a week</li>
                                                        <li>3-Week runs every 3 weeks</li>
                                                        <li>1-Month runs every month</li>
                                                        <li>3-Month runs every 3 months</li>
                                                        <li>Once - will run once on selected date</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useRoute } from 'vue-router'
import ConnectionsService from "../services/connectionsService";
import { FormWizard, TabContent } from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import draggable from 'vuedraggable';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ConnectionComponent from "@/components/ConnectionComponent.vue";
import Datepicker from 'vuejs3-datepicker';
import VueTimePicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";

export default {
    name: "ProjectJobsView",
    data: () => {
        return {
            currentTabIndex: 0,
            project: {},
            loading: true,
            tables: [],
            fields: [],
            filteredFields: [],
            filteredDateFields: [],
            allChecked: false,
            allDateChecked: false,
            searchTerm: null,
            searchDateFieldTerm: null,
            selectedTableId: null,
            job: {
                name: null,
                tasks: [],
                calendar: {
                    step: 0,
                    occurrence: 0,
                    dateStart: null,
                    period: 0
                }
            },
            wheres: [],
            joins: [],
            joinTableFields: [],
            where: {
                id: null,
                field: null,
                action: null,
                value: null,
                operator: null,
            },
            join: {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: null,
                operator: null,
                tableId: null,
                tableDestination: null,
                group: null
            },
            checkedTables: [],
            newTable: null,
            stepError: false,
            dateStartError: false,
            dateFromError: false,
            dateToError: false,
            timeFormat: "HH:mm",
            timeData: {
                HH: null,
                mm: null
            },
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1))
            },
            overrideDates: false
        };
    },
    components: {
        LoadingIndicator,
        FormWizard,
        TabContent,
        draggable,
        ConnectionComponent,
        Datepicker,
        VueTimePicker
    },
    computed: {
        selectedWheres() {
            return this.wheres.filter(x => x.selectedTableId == this.selectedTableId);
        },
        selectedJoins() {
            return this.joins.filter(x => x.selectedTableId == this.selectedTableId);
        }
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        },
        searchDateFieldTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchDateFieldTerm?.toLowerCase()) > -1);
            } else {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        }
    },
    created() {
        const route = useRoute();
        this.projectId = route.params.id;
        this.jobId = route.params.jobId

        this.getProject();
    },
    updated() {
        this.checkedTables = this.tables.filter(x => x.isChecked);
    },
    methods: {
        selectTable(tableId) {
            this.selectedTableId = tableId;
            this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.isChecked) ? -1 : 1).sort((a, b) => (a.fieldname < b.fieldname && a.isChecked) ? -1 : 1);
            this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.checkedDate) ? -1 : 1).sort((a, b) => (a.fieldname < b.fieldname && a.checkedDate) ? -1 : 1);
            this.searchTerm = null;
            this.searchDateFieldTerm = null;
        },
        allCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId)?.fields?.filter(x => !x.isChecked).length > 0;
        },
        allDateCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId)?.fields?.filter(x => !x.checkedDate).length > 0;
        },
        checkedFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId)?.fields?.filter(x => x.isChecked)?.length ?? 0;
        },
        checkedDateFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId)?.fields?.filter(x => x.checkedDate)?.length ?? 0;
        },
        checkTable(id, isChecked) {
            this.tables.find(x => x.id == id).isChecked = !isChecked;
            this.checkedTables = this.tables.filter(x => x.isChecked);
        },
        checkField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).isChecked = !isChecked;
        },
        checkDateField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).checkedDate = !isChecked;
        },
        checkAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.isChecked = !this.allChecked; })
            this.allChecked = !this.allChecked;
        },
        checkDateAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.checkedDate = !this.allDateChecked; })
            this.allDateChecked = !this.allDateChecked;
        },
        validateConnection() {
            if (!this.project.connectionConfigurationId) {
                return false;
            }

            return true;
        },
        getProject() {
            ProjectsService.getProject(this.projectId).then(
                (project) => {
                    this.project = project;
                    let job = project.jobs.find(x => x.id == this.jobId);

                    this.job.name = job.name
                    this.job.id = job.id;
                    this.job.calendar = job.calendar;

                    this.timeData.HH = setTimeData(job.calendar.hours);
                    this.timeData.mm = setTimeData(job.calendar.minutes);

                    function setTimeData(value) {
                        if (value) {
                            if (value < 10) {
                                return "0" + value;
                            }
                            return value;
                        }
                        else {
                            return "";
                        }
                    }

                    if (this.job.calendar.occurrence == "ONCE" && this.job.calendar.period == "NONE" && this.job.calendar.dateFrom) {
                        this.overrideDates = true;
                    }

                    this.getTables();
                });
        },
        getTables() {
            this.loading = true;
            this.tables = []
            ProjectsService.getTables(this.project.id, this.project.connectionConfigurationId, this.jobId).then(
                (tables) => {
                    tables.forEach((item) => {

                        let tasks = this.project.jobs.find(x => x.id == this.jobId).tasks
                        let task = tasks.find(x => x.table.name == item.tabname);

                        if (task && task.table) {
                            task.table.joinClauses.forEach(j => {
                                this.joins.push({
                                    selectedTableId: item.id,
                                    join: j
                                });
                            });

                            task.table.whereClauses.forEach(w => {
                                this.wheres.push({
                                    selectedTableId: item.id,
                                    where: w
                                });
                            });
                        }

                        item.isChecked = task != null || false;
                        item.order = task != null ? task.order : 999;

                        item.fields.forEach((f) => {

                            f.isChecked = false;
                            f.checkedDate = false;

                            let tasks = this.project.jobs.find(x => x.id == this.jobId).tasks
                            let task = tasks.find(x => x.table.name == item.tabname);
                            if (task != null) {
                                let field = task.table.fields.find(x => x.name == f.fieldname);
                                f.isChecked = field != null || false;
                                f.checkedDate = task.dateFields.includes(f.fieldname);
                            }
                        })

                        this.tables.push(item);
                    })

                    this.tables.sort((a, b) => (a.order > b.order) ? 1 : -1)

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        changeTableFields(event) {
            this.joinTableFields = this.tables.find(x => x.id == event.target.value).fields;
        },
        saveWhereClause() {
            let where = {
                selectedTableId: this.selectedTableId,
                where: this.where
            };

            this.wheres.push(where);
            this.where = {
                field: null,
                action: null,
                value: null,
                operator: null,
            };
        },
        saveJoin() {

            let tableSelected = this.tables.find(x => x.id == this.join.tableId);

            let join = {
                selectedTableId: this.selectedTableId,
                join: this.join
            };

            join.join.tableDestination = tableSelected.tabname;

            this.joins.push(join);
            this.join = {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: null,
                operator: null,
                tableId: null,
                tableDestination: null
            };
        },
        deleteWhere(field, action, value, operator) {
            let index = this.wheres.findIndex(x =>
                x.where.field == field &&
                x.where.action == action &&
                x.where.value == value &&
                x.where.operator == operator &&
                x.selectedTableId == this.selectedTableId);
            this.wheres.splice(index, 1)
        },
        deleteJoin(fieldSource, action, tableDestination, fieldDestination, operator, group) {
            let index = this.joins.findIndex(x =>
                x.join.fieldSource == fieldSource &&
                x.join.action == action &&
                x.join.tableDestination == tableDestination &&
                x.join.fieldDestination == fieldDestination &&
                x.join.operator == operator &&
                x.join.group == group &&
                x.selectedTableId == this.selectedTableId);
            this.joins.splice(index, 1)
        },
        finalize() {

            this.loading = true;
            this.stepError = false;
            this.dateStartError = false;
            this.dateToError = false;
            this.dateFromError = false;

            if (this.job.name == null) {
                const $toast = useToast();
                $toast.error('Application error: You need to specify project name!');
                this.loading = false;
                return;
            }
            this.job.tasks = [];

            this.checkedTables.forEach((i, index) => {

                var checkedFields = [];
                var checkedDates = [];

                i.fields.forEach(f => {
                    if (f.isChecked) {
                        checkedFields.push(f.fieldname);
                    }

                    if (f.checkedDate) {
                        checkedDates.push(f.fieldname);
                    }
                })

                let task = {
                    name: i.tabname,
                    dateFields: checkedDates,
                    fields: checkedFields,
                    whereClauses: this.getWheres(this.wheres.filter(x => x.selectedTableId == i.id)),
                    joinClauses: this.getJoins(this.joins.filter(x => x.selectedTableId == i.id)),
                    order: index
                }

                this.job.tasks.push(task);
            });

            if (this.job.calendar.occurrence != "NONE" && this.job.calendar.occurrence != "ONCE") {
                if (this.job.calendar.step == 0) {
                    this.stepError = true;

                    const $toast = useToast();
                    $toast.error('You need to define value for frequency');
                    this.loading = false;
                    return;
                }
            }

            if (!this.job.calendar.dateStart) {
                this.dateStartError = true;

                const $toast = useToast();
                $toast.error('You need to define start date for occurrence');
                this.loading = false;
                return;
            }

            if (this.overrideDates && !this.job.calendar.dateFrom) {

                const $toast = useToast();
                $toast.error('You need to define custom date FROM filter');
                this.loading = false;
                return;
            }

            if (this.overrideDates && !this.job.calendar.dateTo) {
                this.dateToError = true;

                const $toast = useToast();
                $toast.error('You need to define custom date TO filter');
                this.loading = false;
                return;
            }

            this.job.calendar.hours = this.timeData.HH;
            this.job.calendar.minutes = this.timeData.mm;
            this.loading = true;
            ProjectsService.updateJob(this.projectId, this.job).then(
                () => {
                    this.$router.push({ name: 'ProjectDetails', params: { id: this.projectId } });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        getWheres(selectedWheres) {
            let wheres = []

            selectedWheres.forEach((w) => wheres.push(w.where));

            return wheres;
        },
        getJoins(selectedJoins) {
            let joins = []

            selectedJoins.forEach((j) => joins.push(j.join));

            return joins;
        },
        async addNewTable() {

            if (!this.newTable) {
                const $toast = useToast();
                $toast.error('Application error: You need to specify table name!');
                return;
            }

            try {
                this.loading = true;
                await ConnectionsService.extractSpecificTable(this.project.connectionConfigurationId, this.project.id, this.jobId, this.newTable).then(
                    (e) => {
                        console.log(e)
                        this.getTables();
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error.message)
                        const $toast = useToast();
                        $toast.error('There was an error, please try again later or contact support');
                    })
            }
            catch (error) {
                const $toast = useToast();
                $toast.error(error);
                this.loading = false;
                return;
            }

        },
        dateOverrideChange() {
            if (this.overrideDates) {
                this.job.calendar.occurrence = "ONCE"
            }
            else {
                this.job.calendar.occurrence = "NONE"
            }
        },
        async removeTable(tableName){
            try {
                this.loading = true;
                await ConnectionsService.deleteSpecificTable(this.project.id,this.jobId, tableName).then(
                    (e) => {
                        console.log(e)
                        this.getTables();
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error.message)
                        const $toast = useToast();
                        $toast.error('There was an error, please try again later or contact support');
                    })
            }
            catch (error) {
                const $toast = useToast();
                $toast.error(error);
                this.loading = false;
                return;
            }
        }
    }
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}

.list-inline-item {
    border: 1px #d1d1d1 solid;
}

.time-preview {
    border: 1px solid #eee;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 15px;
    background: rgb(70, 25, 173);
    color: #fff;
}

.time-preview-time {
    font-size: 30px;
}

.vue-form-wizard .wizard-card-footer {
    padding: 0 10px;
    padding-top: 20px;
}

[v-cloak] {
    display: none;
}

.vue__time-picker input.vue__time-picker-input {
    width: 200px;
}

.active-table{
    background-color: #cdf4ff; color:#4e73df;
}
.inactive-table{
    background-color: #FFF; color:#4e73df;
    border: 1px solid #04b187;
}

</style>
