import { createRouter, createWebHistory } from "vue-router";
import LayoutView from "../views/LayoutView.vue";
import LoginView from "../views/LoginView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import UsersView from "../views/UsersView.vue";
import DashboardView from "../views/DashboardView.vue";
import ConnectionsView from "../views/ConnectionsView.vue";
import ProjectDetailsView from "../views/ProjectDetailsView.vue";
import ProjectJobsView from "../views/ProjectJobsView.vue";
import ProjectJobEditView from "../views/ProjectJobEditView.vue";
import TemplatesView from "../views/TemplatesView.vue";
import TemplateDetailsView from "../views/TemplateDetailsView.vue";
import ClientsView from "../views/ClientsView.vue";
import UsersService from "../services/usersService.js";
import ProjectJobFilesView from "../views/ProjectJobFilesView.vue";
import ProfileView from "../views/ProfileView.vue";
import ChangePasswordView from "../views/ChangePasswordView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import ProfileEditView from "../views/ProfileEditView.vue";
import StorageViewerView from "../views/StorageViewerView.vue";
import UserView from "../views/UserView.vue";
import PartnersView from "../views/PartnersView.vue"
import PartnerClientsView from "../views/PartnerClientsView.vue";
import TenantSettings from "@/views/TenantSettings.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
    
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPasswordView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
    
  },
  {
    path: "",
    component: LayoutView,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
      },
      {
        path: "/projects",
        name: "Projects",
        component: ProjectsView,
      },
      {
        path: "/projects/:id",
        name: "ProjectDetails",
        component: ProjectDetailsView,
      },
      {
        path: "/projects/:id/jobs",
        name: "ProjectJobs",
        component: ProjectJobsView,
      },
      {
        path: "/projects/:id/jobs/edit/:jobId",
        name: "ProjectJobEdit",
        component: ProjectJobEditView,
      },
      {
        path: "/connections",
        name: "Connections",
        component: ConnectionsView,
      },
      {
        path: "/users",
        name: "Users",
        component: UsersView,
      },
      {
        path: "/users/:id",
        name: "User",
        component: UserView,
      },
      {
        path: "/templates",
        name: "Templates",
        component: TemplatesView,
      },
      {
        path: "/templates/:id",
        name: "TemplateDetails",
        component: TemplateDetailsView,
      },
      {
        path: "/clients",
        name: "Clients",
        component: ClientsView,
      },
      {
        path:"/job/:jobId/files",
        name:"ProjectJobFiles",
        component:ProjectJobFilesView
      },
      {
        path: "/edit-profile",
        name: "EditProfile",
        component: ProfileEditView,
      },
      {
        path: "/profile",
        name: "Profile",
        component: ProfileView,
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePasswordView,
      },
      {
        path: "/storage",
        name: "StorageViewer",
        component: StorageViewerView,
      },
      {
        path: "/partners",
        name: "Partners",
        component: PartnersView,
      },
      {
        path: "/partners/:id",
        name: "PartnerClients",
        component: PartnerClientsView,
      },
      {
        path: "/settings",
        name: "TenantSettings",
        component: TenantSettings,
      },      
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/forgot-password", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("access_token");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    if (to.path == "/clients") {
      UsersService.getCurrent().then((user) => {
        if (
          user.roles.filter((x) => x == "SuperAdmin" || x == "PartnerAdmin")
            .length == 0
        ) {
          next("/projects");
        }

        next();
      });
    }
    else if (to.path == "/users") {
      UsersService.getCurrent().then((user) => {
        if (
          user.roles.filter((x) => x == "SuperAdmin" || x == "PartnerAdmin"  || x == "ClientAdmin")
            .length == 0
        ) {
          next("/projects");
        }

        next();
      });
    }

    else{
      next();
    }
  }
});

export default router;
