<template>
    <div class="template">
        <LoadingIndicator v-if="loading"></LoadingIndicator>
        <HeadingComponent title="Templates" description="All your templates" action="Create a new template"
            action-enabled="false" action-modal="">
        </HeadingComponent>

        <ContentWrapper>
            <div class="row mr-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3">

                    <div class="card shadow-sm">
                        <div class="card-header">
                            <div class="d-sm-flex align-items-center justify-content-between"
                                style="background-color: #fff">
                                <h6 class="m-0 font-weight-bold text-gray-600">
                                    Upload logo
                                </h6>
                            </div>
                        </div>
                        <div class="card-body" style="min-height: 100px; color: #7b7b7b">
                            <input type="file" class="btn btn-sm btn-primary ml-2" ref="fileInput"
                                v-on:change="handleFileChange($event)" />
                        </div>
                        <div class="card-footer">
                            <h4 class="h6 text-info">
                                <button class="btn btn-sm btn-primary ml-2" v-on:click="uploadLogo()">Upload</button>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
import LoadingIndicator from "../components/LoadingIndicator.vue";
import HeadingComponent from "../components/HeadingComponent.vue";
import ClientsService from "../services/clientsService";
import ContentWrapper from "../components/ContentWrapper.vue";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export default {
    name: "TenantSettings",
    data: () => {
        return {
            settings: null,
            name: null,
            description: null,
            loading: true,
            logoFile: null,
            logoWidth: null,
            logoHeight: null
        };
    },
    components: {
        LoadingIndicator,
        HeadingComponent,
        ContentWrapper,
    },
    created() {
        this.loading = false;
    },
    methods: {
        getSettings() {
            ClientsService.getSettings().then((settings) => {
                this.settings = settings;
                this.loading = false;
            });
        },
        handleFileChange(e) {
            this.logoFile = e.target.files[0];
        },
        async uploadLogo() {

            this.loading = true;

            if (!this.validateLogo()) {

                this.loading = false;
                return;
            }

            this.getUploadedFileDimensions(this.logoFile).then((res) => {
                if (!res) {

                    const $toast = useToast();
                    $toast.error("Image size is too big!");
                    this.loading = false;
                    return;
                }
                
                const formData = new FormData();
                    formData.append('logo', this.logoFile);

                    ClientsService.uploadLogo(formData)
                        .then(() => {
                            this.loading = false;
                            this.logoFile = null;
                            location.reload();
                        })
                        .catch((error) => {
                            this.loading = false;
                            console.log(error.message);
                            const $toast = useToast();
                            $toast.error(
                                "There was an error, please try again later or contact support"
                            );
                        });
            })
        },
        validateLogo() {

            const $toast = useToast();

            if (!this.logoFile || this.logoFile.type.indexOf('image/') !== 0) {

                $toast.error("File is not image!");

                return false;
            }

            if (this.logoFile.size > 102400) {

                $toast.error("File size is too big!");
                return false;
            }

            return true;
        },
        getUploadedFileDimensions: file => new Promise((resolve, reject) => {
            try {
                let img = new Image()

                img.onload = () => {
                    window.URL.revokeObjectURL(img.src)

                    if (img.naturalWidth > 100 || img.naturalHeight > 100) {
                        return resolve(false)
                    } else {
                        return resolve(true)
                    }
                }

                img.src = window.URL.createObjectURL(file)
            } catch (exception) {
                return reject(exception)
            }
        }),
        getLogo() {
            ClientsService.getLogo()
                .then(() => {
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error.message);
                    const $toast = useToast();
                    $toast.error(
                        "There was an error, please try again later or contact support"
                    );
                });
        },
    },
};
</script>

<style>
a {
    text-decoration: none !important;
}

.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}
</style>
