<template>  
    <router-view></router-view>
</template>
<script>
export default {
  created () {
    const access_token = JSON.parse(localStorage.getItem("access_token") || "{}");
    this.$socket.authenticate(access_token,{
      log: true // Logging is optional but very helpful during development
    })
  },
  updated () {
    const access_token = JSON.parse(localStorage.getItem("access_token") || "{}");
    this.$socket.authenticate(access_token,{
      log: true // Logging is optional but very helpful during development
    })
  },
  sockets: {
    ProjectExtractionStatus () {
      // this has to be here even though it's not being used?
      // otherwise other events don't actually fire
    },
    ProjectJobExtractionStatus(){
      
    },
    JobExtractionInfo(){
      
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
}

#wrapper #content-wrapper {
    background-color: #fff;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bg-gradient-primary {
  background-color: #ffffff;
  background-image: none;
  background-size: cover;
}

.sidebar-dark .nav-item .nav-link {
  color: #7B7B7B;
}

.sidebar .nav-item .nav-link span {
    font-family: 'Inter';
    font-style: normal;
}

.sidebar .nav-item .nav-link {
    font-family: 'Inter';
    font-style: normal;
}

.sidebar .nav-item:hover {
    text-decoration: none;
    color: #0469B1;
    background-color: #EBF7FF;
  }

  .nav-item:hover > a > span {
    color: #0469B1;
    font-weight:bold;
  }
.container-fluid{
    background-color: #fff;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 0;
    padding-right: 0;
}
.project{
  overflow: hidden;
}

[v-cloak] {
  display: none;
}

</style>
