<template>
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row" style="min-height:100px;">
                            <div class="col-lg-5">
                                <img src="../assets/images/huro_data_icon.svg" />
                                <div class="d-flex align-items-center">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h1 text-gray-900 mb-4">Forgotten password</h1>
                                        </div>
                                        <form class="user">
                                            <div class="form-group">
                                                <input type="email" v-model="email"
                                                    :class="{ 'border border-danger': emailError }"
                                                    class="form-control form-control-user" id="exampleInputEmail"
                                                    aria-describedby="emailHelp" placeholder="Enter Email Address...">
                                            </div>
                                            <a href="#" class="btn btn-primary btn-user btn-block" @click="reset()">
                                                Reset
                                            </a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 d-none d-lg-block" style="max-height: 600px;">
                                <img src="../assets/images/signing.jpeg" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
    name: 'LoginView',
    data() {
        return {
            email: null,
            loading: false,
            emailError: false
        }
    },
    methods: {
        reset() {

            if (!this.email) {

                this.emailError = true;
                return;
            }
            this.loading = true;
            UsersService.passwordResetToken(this.email).then(() => {
                const $toast = useToast();
                $toast.success('If user exists, mail with reset link will be sent!');
            })
                .catch(() => {
                    this.loading = false;
                    const $toast = useToast();
                    $toast.error('Something went wrong while resetting password!');
                })
        },
    }
}
</script>

<style scoped>
.center {
    padding: 280px 0;
    text-align: center;
    font-size: 24px;
    color: white;
}
</style>