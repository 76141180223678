import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const ManagementService = {
  async deleteClient(id) {
    try {
      const response = await ApiService.delete(`/management/tenants/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async deleteUser(id) {
    try {
      const response = await ApiService.delete(`/management/users/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
};

export default ManagementService;

export { ManagementService, ApplicationError };
