<template>
    <div class="project">

        <LoadingIndicator v-if="loading"></LoadingIndicator>
        <HeadingComponent title="Projects" description="Add your projects here and start extracting"
            action="Create a new project" action-modal="#newProjectModal" action-enabled="true"> </HeadingComponent>

        <!-- Create new project Modal-->
        <div class="modal fade" id="newProjectModal" tabindex="-1" role="dialog" aria-labelledby="newProjectModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Create a project</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="">
                            <div class="input-group">
                                <input type="text" class="form-control bg-light border small" v-model="projectName"
                                    :class="{ 'border border-danger': projectNameError }" placeholder="Project name"
                                    aria-label="Create" aria-describedby="basic-addon2" />
                            </div>
                            <div class="input-group mt-2">
                                <span style="min-width:100px;">Date start:</span>
                                <datepicker v-model="startDate" :class="{ 'border border-danger': startDateError }">
                                </datepicker>
                            </div>
                            <div class="input-group mt-2">
                                <span style="min-width:100px;">Date end:</span>
                                <datepicker v-model="endDate" :class="{ 'border border-danger': endDateError }">
                                </datepicker>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <a class="btn btn-primary" @click="createProject()">Create</a>
                    </div>
                </div>
            </div>
        </div>

        <ContentWrapper>

            <SearchBarComponent title="All projects"></SearchBarComponent>

            <div class="row mr-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3" v-for="project in filteredProjects" :key="project.id">
                    <div class="card shadow-sm" style="min-height:240px;">
                        <div class="card-header">
                            <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
                                <h6 class="m-0 font-weight-bold text-gray-600" v-if="project.connection">
                                    <img src="../assets/images/sap.svg" /> {{ project.connection.name }}
                                </h6>
                                <h6 class="m-0 font-weight-bold text-gray-600" v-else>
                                    No connection
                                </h6>
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item dropdown no-arrow">
                                        <a class="nav-link dropdown-toggle" href="#" id="projectDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in"
                                            aria-labelledby="projectDropdown">
                                            <a class="dropdown-item text-info" href="#" v-on:click="copyProject(project.id)">
                                                <i class="fa fa-regular fa-copy"></i>
                                                Copy project
                                            </a>
                                            <a class="dropdown-item text-warning" href="#" data-dismiss="modal" v-on:click="selectProject(project)"
                                                data-toggle="modal" data-target="#updateProjectModal">
                                                <i class="fa fa-duotone fa-pen-to-square"></i>
                                                Edit project
                                            </a>
                                            <a class="dropdown-item text-danger" href="#" data-dismiss="modal" v-on:click="selectProjectId(project.id)"
                                                data-toggle="modal" data-target="#removeProjectModal">
                                                <i class="fa-solid fa-trash-can"></i>
                                                Remove project
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <h1 class="h4">{{ project.name }}</h1>
                            <h4 class="h6">
                                <i class="fa-solid fa-clipboard-list"></i> {{ project.jobs.length }}
                            </h4>
                        </div>
                        <div class="card-footer">
                            <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
                                <router-link :to="{
                                    name: 'ProjectDetails',
                                    params: { id: project.id }
                                }" class="btn btn-outline-primary btn-sm">
                                    See project
                                </router-link>
                                <div>
                                    <span v-if="project.status == 'EXTRACTION_COMPLETED'" href="#"
                                        class="d-none d-sm-inline-block shadow-sm badge badge-success p-2">{{ project.status
                                        }}</span>
                                    <span v-else-if="project.status == 'EXTRACTION_NEW'" href="#"
                                        class="d-none d-sm-inline-block shadow-sm badge badge-info p-2">{{ project.status
                                        }}</span>
                                    <span v-else-if="project.status == 'EXTRACTION_ERROR'" href="#"
                                        class="d-none d-sm-inline-block shadow-sm badge badge-danger p-2">{{ project.status
                                        }}</span>
                                    <span v-else class="d-none d-sm-inline-block shadow-sm badge badge-warning p-2">{{
                                        project.status }}</span>
                                    <a href="#" class="ml-1" v-on:click.prevent="getProjects()"><i
                                            class="fa-solid fa-arrows-rotate"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Update project Modal-->
                <div class="modal fade" id="updateProjectModal" tabindex="-1" role="dialog"
                    aria-labelledby="updateProjectModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="updateProjectModalLabel">Update project</h5>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="">
                                    <div class="input-group">
                                        <div style="min-width:100px;">Name: </div>
                                        <input type="text" class="form-control bg-light small" v-model="selectedProject.name"
                                            :class="{ 'border border-danger': projectNameError }" placeholder="Project name"
                                            aria-label="Update" aria-describedby="basic-addon2" />
                                    </div>
                                    <div class="input-group mt-2">
                                        <span style="min-width:100px;">Date start:</span>
                                        <datepicker :class="{ 'border border-danger': startDateError }"
                                            v-model="selectedProject.dateStart"></datepicker>
                                    </div>
                                    <div class="input-group mt-2">
                                        <span style="min-width:100px;">Date end:</span>
                                        <datepicker :class="{ 'border border-danger': endDateError }"
                                            v-model="selectedProject.dateEnd"></datepicker>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                    Cancel
                                </button>
                                <a class="btn btn-warning" @click="updateProject()">Update</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="removeProjectModal" tabindex="-1" role="dialog"
                    aria-labelledby="removeProjectModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h6>Are you sure you want to remove project?</h6>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                    Cancel
                                </button>
                                <a class="btn btn-primary" data-dismiss="modal" v-on:click="removeProject()">Remove</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import ConnectionsService from "../services/connectionsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Datepicker from 'vuejs3-datepicker';
import HeadingComponent from '../components/HeadingComponent.vue'
import SearchBarComponent from '../components/SearchBarComponent.vue'
import ContentWrapper from '../components/ContentWrapper.vue'
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
    name: "ProjectsView",
    data: () => {
        return {
            projects: [],
            connections: [],
            searchTerm: null,
            filteredProjects: [],
            projectName: null,
            loading: true,
            startDate: null,
            endDate: null,
            url: process.env.VUE_APP_URL,
            orderBy: false,
            sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
            projectNameError: false,
            startDateError: false,
            endDateError: false,
            selectedProjectId: 0,
            selectedProject: {
                name: null,
                dateStart: null,
                dateEnd: null
            }
        };
    },
    components: {
        LoadingIndicator,
        Datepicker,
        HeadingComponent,
        SearchBarComponent,
        ContentWrapper
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredProjects = this.projects.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredProjects = this.projects
            }
        },
        projectName(newTerm) {
            if (!newTerm) {
                this.projectNameError = true;
            } else {
                this.projectNameError = false;
            }
        },
        startDate(newTerm) {
            if (!newTerm) {
                this.projectNameError = true;
            } else {
                this.projectNameError = false;
            }
        },
        endDate(newTerm) {
            if (!newTerm) {
                this.projectNameError = true;
            } else {
                this.projectNameError = false;
            }
        }
    },
    created() {
        this.getProjects();
        this.getConnections();

        this.$socket.on('ProjectExtractionStatus', data => {
            let project = this.projects.find(x => x.id == data.id);
            if (project) {
                project.status = data.status;
            }
        });

        this.$socket.invoke('AddToGroup').then(() => { })

    },
    methods: {
        getProjects() {
            this.loading = true;
            ProjectsService.getProjects(this.orderBy).then(
                (projects) => {
                    this.projects = projects;
                    this.filteredProjects = this.projects;
                    this.loading = false;
                });
        },
        selectProjectId(id){
            this.selectedProjectId = id;
        },
        selectProject(project){
            this.selectedProject = project;
        },
        sort() {
            this.orderBy = !this.orderBy;
            this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
            this.getProjects();
        },
        getConnections() {
            ConnectionsService.getConnections().then((connections) => { this.connections = connections; });
        },
        copyProject(id) {
            ProjectsService.copyProject(id)
                .then(() => {
                    const $toast = useToast();
                    $toast.success('Project is copied');
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        removeProject() {
            ProjectsService.deleteProject(this.selectedProjectId)
                .then(() => {
                    this.getProjects();
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        updateProject() {

            this.loading = true;

            if (!this.validateUpdate()) {
                this.loading = false;
                return;
            }

            ProjectsService.updateProject(this.selectedProject.id, this.selectedProject)
                .then(() => {
                    const $toast = useToast();
                    $toast.success('Project is updated');
                    // eslint-disable-next-line
                    $('#updateProjectModal').modal('hide');
                    this.getProjects();
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                    // eslint-disable-next-line
                    $('#updateProjectModal').modal('hide');
                })
        },
        createProject() {

            if (!this.validate()) {
                return;
            }

            let project =
            {
                Name: this.projectName,
                DateStart: this.startDate,
                DateEnd: this.endDate
            };

            ProjectsService.createProject(project).then(
                (id) => {
                    // eslint-disable-next-line
                    $('#newProjectModal').modal('hide');
                    this.$router.push({ name: 'ProjectDetails', params: { id: id } });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        validate() {
            let isOk = true;

            if (!this.projectName) {
                this.projectNameError = true;
                isOk = false;
            }

            if (!this.startDate) {
                this.startDateError = true;
                isOk = false;
            }

            if (!this.endDate) {
                this.endDateError = true;
                isOk = false;
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        },
        validateUpdate() {
            let isOk = true;

            if (!this.selectedProject.name) {
                this.projectNameError = true;
                isOk = false;
            }

            if (!this.selectedProject.dateStart) {
                this.startDateError = true;
                isOk = false;
            }

            if (!this.selectedProject.dateEnd) {
                this.endDateError = true;
                isOk = false;
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        }
    },
};
</script>

<style scoped>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}
</style>
