import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const UsersService = {
  async getUsers(orderBy) {
    try {
      const response = await ApiService.get(`/users/ordered/${orderBy}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async getUser(id) {
    try {
      const response = await ApiService.get(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async getPartners() {
    try {
      const response = await ApiService.get('/users/partners');
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async getCurrent() {
    try {
      const response = await ApiService.get('/users/current');
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async getRoles() {
    try {
      const response = await ApiService.get("/users/roles");
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async createUser(model) {
    try {
      const response = await ApiService.post("/users", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async updateUser(id, user) {
    try {
      const response = await ApiService.put(`/users/${id}`, user);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async activateUser(id) {
    try {
      const response = await ApiService.put(`/users/activate/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async deleteUser(id) {
    try {
      const response = await ApiService.delete(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async assignRoleToUser(userId, roleId) {
    try {
      const response = await ApiService.put(
        `/users/add-to-role/${userId}`,
        roleId
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async removeRoleFromUser(userId, roleId) {
    try {
      const response = await ApiService.put(
        `/users/remove-from-role/${userId}`, roleId
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async assignAdmin(userId, tenantId) {
    try {
      const response = await ApiService.post(`/users/${userId}/assign-admin/${tenantId}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async removeAdmin(userId, tenantId) {
    try {
      const response = await ApiService.delete(`/users/${userId}/remove-admin/${tenantId}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async changeUserPassword(id) {
    try {
      const response = await ApiService.post(`/users/${id}/change-password`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async changeContext(tenantId) {
    try {
      const response = await ApiService.post('/users/change-context', tenantId);
      localStorage.setItem("tenantId", tenantId);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async updateMe(user) {
    try {
      const response = await ApiService.put('/users/me', user);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async changePassword(password) {
    try {
      const response = await ApiService.put('/users/me/change-password', password);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async passwordResetToken(email) {
    try {
      const response = await ApiService.put(`/users/change-password-reset-token/${email}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
  async resetPassword(data) {
    try {
      const response = await ApiService.put('/users/reset-password/', data);
      return response.data;
    } catch (error) {
      throw new ApplicationError(error.code, error);
    }
  },
};

export default UsersService;

export { UsersService, ApplicationError };
