
<template>
    <!-- Content Row -->
    <div class="row hd-bg-color" style="min-height: 100vh;">
        <div class="col-lg-12 mb-4 ml-5 mt-4 col-md-8 col-xs-4">
            <slot></slot>
        </div>
    </div>
</template>
    
<script>

export default {
}
</script>