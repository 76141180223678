import ApiService from "./apiService";
import ApplicationError from "../common/ApplicationError.js";

const TemplatesService = {
  async getTemplates(orderBy) {
    try {
      const response = await ApiService.get(`/templates/ordered/${orderBy}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async getTemplate(id) {
    try {
      const response = await ApiService.get(`/templates/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async createTemplate(model) {
    try {
      const response = await ApiService.post("/templates/", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async updateTemplate(id, template) {
    try {
      const response = await ApiService.put(`/templates/${id}`, template);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async deleteTemplate(id) {
    try {
      const response = await ApiService.delete(`/templates/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
  async copyFromJob(jobId) {
    try {
      const response = await ApiService.post(`/templates/job/${jobId}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error
      );
    }
  },
};

export default TemplatesService;

export { TemplatesService, ApplicationError };
