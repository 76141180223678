
<template>
<!-- Page Heading -->
<div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
    <h1 class="h3 text-gray-800">{{title}}</h1>
    <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm mr-5" data-toggle="modal"
        :data-target="actionModal" v-if="actionEnabled=='true'"><i class="fa-solid fa-plus"></i>
        {{ action }}
    </a>
</div>
<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h6 class="h6 text-gray-600">
        {{description}}
    </h6>
</div>
</template>

<script>

export default {
    props: ['title', 'description', 'action', 'actionModal','actionEnabled']
}
</script>