<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion mt-4 ml-2 navbar-fixed-top"
      id="accordionSidebar" style="position: fixed;">
      <!-- Sidebar - Brand -->
      <router-link to="/projects" class="sidebar-brand d-flex align-items-center ml-2">
        <div class="sidebar-brand-text">
          <!-- <img src="https://localhost:7196/api/tenant-settings/logo" /> -->
          <img v-bind:src=logo />
        </div>
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Dashboard
      <li class="nav-item">
        <router-link to="/dashboard" class="nav-link"><i class="fa-solid fa-table-columns"></i>
          <span> Dashboard</span>
        </router-link>
      </li> -->

      <li class="nav-item mt-3" v-if="currentUser">
        <router-link to="/projects" class="nav-link"><i class="fa-regular fa-calendar-minus"></i>
          <span> Projects</span></router-link>
      </li>

      <li class="nav-item" v-if="currentUser">
        <router-link to="/connections" class="nav-link"><i class="fa-solid fa-network-wired"></i>
          <span> Connections</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isAdmin || isClientAdmin">
        <router-link to="/templates" class="nav-link"><i class="fa-solid fa-rectangle-list"></i>
          <span> Templates</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isSuperAdmin">
        <router-link to="/partners" class="nav-link"><i class="fa-regular fa-handshake"></i>
          <span> Partners </span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isAdmin">
        <router-link to="/clients" class="nav-link"><i class="fa-solid fa-building-columns"></i>
          <span> Clients</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isAdmin || isClientAdmin">
        <router-link to="/users" class="nav-link"><i class="fa-solid fa-users"></i>
          <span> Users</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isSuperAdmin">
        <router-link to="/storage" class="nav-link"><i class="fa-solid fa-users"></i>
          <span> Storage</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isAdmin">
        <router-link to="/settings" class="nav-link"><i class="fa-solid fa-users"></i>
          <span> Settings</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block" />
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column" style="padding-left: 16rem;">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
          <!-- Sidebar Toggle (Topbar) -->
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>

          <div class="form" v-if="isAdmin">
            <div class="form-group row">
              <div class="col-sm-6 mb-3 mb-sm-0 mt-5">
                <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
                  <select v-model="tenantId" class="form-control form-control-sm" data-toggle="tooltip"
                    style="min-width: 220px;" data-placement="top" title="Client context" id="tenant-edit"
                    placeholder="Client context">
                    <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                  </select>
                  <button class="btn btn-sm btn-primary ml-2" v-on:click="changeContext()">Change</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Topbar Navbar -->
          <ul class="navbar-nav ml-auto">
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none">
              <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-search fa-fw"></i>
              </a>
              <!-- Dropdown - Messages -->
              <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto w-100 navbar-search">
                  <div class="input-group">
                    <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                      aria-label="Search" aria-describedby="basic-addon2" />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                  {{ name }}
                </span>
                <img class="img-profile rounded-circle" src="../assets/images/undraw_profile.svg" />
              </a>
              <!-- Dropdown - User Information -->
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#">
                  <router-link active-class="none" style="font-weight: 100;" exact-active-class="none" to="/edit-profile"><i
                      class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </router-link>
                </a>
                <a class="dropdown-item" href="#">
                  <router-link active-class="none" style="font-weight: 100;" exact-active-class="none"
                    to="/change-password"><i class="fa-solid fa-key mr-2 text-gray-400"></i>
                    Change password
                  </router-link>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <!-- <footer class="sticky-footer bg-white">
              <div class="container my-auto">
                  <div class="copyright text-center my-auto">
                      <span>Copyright &copy; Huro Data 2023</span>
                  </div>
              </div>
          </footer> -->
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" data-dismiss="modal" @click="logout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Bootstrap core JavaScript-->
</template>
<script>

// @ is an alias to /src
import { authStore } from './../stores/authStore'
import ClientsService from "../services/clientsService";
import UsersService from "../services/usersService";
import AuthService from "../services/authService";
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'LayoutView',
  data: () => {
    return {
      clients: [],
      tenantId: localStorage.getItem("tenantId"),
      currentUser: null,
      isAdmin: false,
      isSuperAdmin: false,
      isClientAdmin: false,
      name: null,
      logo: null
    }
  },
  async created() {
    this.getLogo();
    this.getClients();
    UsersService.getCurrent().then(
      (user) => {
        this.currentUser = user;
        this.isAdmin = user?.roles?.filter(x => x == "SuperAdmin" || x == "PartnerAdmin").length > 0
        this.isSuperAdmin = user?.roles?.filter(x => x == "SuperAdmin").length > 0
        this.isClientAdmin = user?.roles?.filter(x => x == "ClientAdmin").length > 0
        this.name = user?.firstName + " " + user?.lastName;
      }
    );


    this.$socket.invoke('AddToGroup').then(() => { })

    // this.$socket.on('ProjectExtractionStatus', data => {
    //   const $toast = useToast();

    //   if (data.status == "EXTRACTION_IN_PROGRESS") {
    //     $toast.info("Project " + data.name + " changed status to " + data.status);
    //   }

    //   if (data.status == "EXTRACTION_ERROR") {
    //     $toast.error("Project " + data.name + " changed status to " + data.status);
    //   }

    //   if (data.status == "EXTRACTION_NEW") {
    //     $toast.success("Project " + data.name + " changed status to " + data.status);
    //   }

    // });

    // this.$socket.on('ProjectJobExtractionStatus', data => {
    //   const $toast = useToast();

    //   if (data.status == "EXTRACTION_IN_PROGRESS") {
    //     $toast.info("Job " + data.name + " changed status to " + data.status);
    //   }

    //   if (data.status == "EXTRACTION_ERROR") {
    //     $toast.error("Job " + data.name + " changed status to " + data.status);
    //   }

    //   if (data.status == "EXTRACTION_NEW") {
    //     $toast.success("Job " + data.name + " changed status to " + data.status);
    //   }
    // });

    // this.$socket.on('JobExtractionInfo', data => {
    //   const $toast = useToast();

    //   $toast.info(data, { duration: 3000 });
    // });
  },
  methods: {
    logout() {
      const authStoreInstance = authStore();
      authStoreInstance.logout()
      this.$router.push("/login");
    },
    getClients() {
      ClientsService.getNonOrderedClients().then((clients) => {
        this.clients = clients;
      });
    },
    changeContext() {

      if (this.tenantId != null && this.tenantId > 0) {
        UsersService.changeContext(this.tenantId).then((token) => {
          AuthService.changeToken(token);
          window.location = "/projects";
        })
          .catch(error => {
            this.loading = false;
            console.log(error.message)
            const $toast = useToast();
            $toast.error('There was an error, please try again later or contact support');
          })

      }
    },
    getLogo(){
      ClientsService.getLogo().then((logo) => {
        this.logo = logo;
      });
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
}

#wrapper #content-wrapper {
  background-color: #fff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bg-gradient-primary {
  background-color: #ffffff;
  background-image: none;
  background-size: cover;
}

.sidebar-dark .nav-item .nav-link {
  color: #7b7b7b !important;
}

.sidebar-dark .nav-item .nav-link i {
  color: #7b7b7b;
}

.sidebar .nav-item .nav-link span {
  font-style: normal;
  font-size: 18px;
  ;
}

.nav-item:hover>a>span {
  color: #0469b1;
  font-weight: bold;
}

.nav-item:hover>a>i {
  color: #0469b1 !important;
  font-weight: bold;
}

.container-fluid {
  background-color: #fff;
}

.nav-link:hover {
  color: #7b7b7b !important;
}

.sidebar-dark .nav-item .nav-link>a>i {
  color: #7b7b7b !important;
}

.router-link-active>i {
  color: #7b7b7b !important;
}

.sidebar .nav-item .nav-link span {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 0;
  padding-right: 0;
}

[v-cloak] {
  display: none;
}
</style>
